import 'common_header/header_menu.js';
import './components/_copy-link.js';
import './animations.js';
import './libraries/custom-select.js';
import './libraries/body-scroll-lock.js';
import './libraries/modal.js';
import './libraries/form-validate.js';
import './libraries/init-form-validate.js';
import Swiper from './libraries/swiper-bundle.min';

window.Swiper=  Swiper;
