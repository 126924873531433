export const openModal = (modal, callback, preventScrollLock) => {
  modal.classList.add('modal--active');

  if (callback) {
    callback();
  }

  if (!preventScrollLock) {
    window.disableBodyScroll(modal, {
      reserveScrollBarGap: true,
    });
  }
};

export const closeModal = (modal, callback, preventScrollLock) => {
  modal.classList.remove('modal--active');

  if (callback) {
    callback();
  }

  if (!preventScrollLock) {
    setTimeout(() => {
      window.enableBodyScroll(modal);
    }, 300);
  }
};

const onEscPress = (evt, modal, callback) => {
  const isEscKey = evt.key === 'Escape' || evt.key === 'Esc';

  if (isEscKey && modal.classList.contains('modal--active')) {
    evt.preventDefault();
    closeModal(modal, callback);
  }
};

const setModalListeners = (modal, closeCallback, preventScrollLock) => {
  const overlay = modal.querySelector('.modal__overlay');
  const closeBtn = modal.querySelector('.modal__close-btn');
  const closeBtnAlt = modal.querySelector('.modal__close-btn-alt');

  closeBtn.addEventListener('click', () => {
    closeModal(modal, closeCallback, preventScrollLock);
  });

  if (closeBtnAlt) {
    closeBtnAlt.addEventListener('click', () => {
      closeModal(modal, closeCallback, preventScrollLock);
    });
  }

  overlay.addEventListener('click', () => {
    closeModal(modal, closeCallback, preventScrollLock);
  });

  document.addEventListener('keydown', (evt) => {
    onEscPress(evt, modal, closeCallback, preventScrollLock);
  });
};

const setupModal = (modal, closeCallback, modalBtn, openCallback, noPrevDefault, preventScrollLock) => {
  if (modalBtn) {
    modalBtn.addEventListener('click', (evt) => {
      if (!noPrevDefault) {
        evt.preventDefault();
      }
      openModal(modal, openCallback, preventScrollLock);
    });
  }

  setModalListeners(modal, closeCallback, preventScrollLock);
};

// настраиваем модалки тут, все колбеки импортим, а не создаем из этого модуля простыню
export const initModal = (modalId, modal, btn) => {
  switch (modalId) {
    case 'example':
      setupModal(modal, false, btn, false, true, true);
      break;
    default:
      setupModal(modal, false, btn, false, false, false);
      break;
  }
};

export const modalSubscribeSuccessMessage = document.querySelector('.modal--subscribe-success-message');

if (modalSubscribeSuccessMessage) {
  setupModal(modalSubscribeSuccessMessage);
}

// аргументы setupModal(modal, closeCallback, modalBtns, openCallback, noPrevDefault, preventScrollLock)
// возможна инициализация только с первыми аргументом,
// если вам нужно открывать модалку в другом месте под какими-нибудь условиями
export const initModals = () => {
  const modals = document.querySelectorAll('.modal:not(.is-initialized)');
  const modalBtns = document.querySelectorAll('[data-modal]');

  // фикс для редких случаев, когда модалка появляется при загрузке страницы
  if (modals.length) {
    modals.forEach((el) => {
      setTimeout(() => {
        el.classList.remove('modal--preload');
        el.classList.add('is-initialized');
      }, 100);
    });
  }

  if (modalBtns.length) {
    modalBtns.forEach((btn) => {
      const modalId = btn.dataset.modal;
      const modal = document.querySelector(`.modal--${modalId}`);
      if (modal) {
        initModal(modalId, modal, btn);
      }
    });
  }
};

window.initModals = initModals();
