document.addEventListener("DOMContentLoaded", () => {
  let html = document.querySelector("html");
  setTimeout(() => html.classList.add("is-dom-ready"), 100);
});

document.addEventListener("DOMContentLoaded", () => {
  const scrollItems = document.querySelectorAll(".scroll-item");
  const cards = document.querySelectorAll(".card");

  if (scrollItems.length) {
    const scrollAnimation = () => {
      scrollItems.forEach((el) => {
        let elementPosY = document.documentElement.clientHeight - el.offsetHeight;
        if (
          el.getBoundingClientRect().top < elementPosY ||
          el.getBoundingClientRect().top <
            document.documentElement.clientHeight / 1.25
        ) {
          el.classList.add("is-active");
        }
      });
      cards.forEach((card) => {
        let elementPosY =
          document.documentElement.clientHeight - card.offsetHeight;
        if (
          card.getBoundingClientRect().top < elementPosY ||
          card.getBoundingClientRect().top <
            document.documentElement.clientHeight + 0.09 * card.offsetHeight
        ) {
          card.classList.add("is-active");
        }
      });
    };

    scrollAnimation();
    window.addEventListener("scroll", () => {
      scrollAnimation();
    });
  }
});

// let patter2Group = document.querySelectorAll("#patter-2 .group");
// let cards = document.querySelectorAll(".card");

// function animatePatter2() {
//   patter2Group.forEach((group, i) => {
//     gsap.from(group, 1.5, {
//       x: "70vw",
//       delay: (i / 10) * 2 + 0.3,
//       ease: Power4.easeOut,
//     });
//   });
// }

// cards.forEach((card) => {
//   card.addEventListener("mouseover", () => {
//     animatePatter2();
//   });
// });

// document.addEventListener("DOMContentLoaded", () => {
//   animatePatter2();
// });

// let animationGroups = document.querySelectorAll(".group animateTransform");
// let cards = document.querySelectorAll(".card");

// animationGroups.forEach((group) => {
//   document.addEventListener("DOMContentLoaded", () => {
//     group.beginElement();
//   });
// });

// cards.forEach((card) => {
//   card.addEventListener("mouseover", () => {
//     animationGroups.forEach((group) => {
//       group.beginElement();
//     });
//   });
// });

// Анимация паттерна при наведении на карточку
let card = document.querySelectorAll(".card");

if (card.length) {
  card.forEach((item) => {
    let animationActive = false;
    let patterWrappers = item.querySelectorAll(".card__pic-bg-img");
    let lastAnimatedPatter = "";
    patterWrappers.forEach((wrapper) => {
      if (
        window.getComputedStyle(wrapper).getPropertyValue("display") === "block"
      ) {
        let wrapperPatters = wrapper.querySelectorAll(".patter__group");
        lastAnimatedPatter = wrapperPatters[wrapperPatters.length - 1];
      }
    });
    item.addEventListener("mouseenter", () => {
      if (!lastAnimatedPatter) return
      if (!animationActive) {
        item.classList.add("card_patter-start-animation");
        animationActive = true;
        lastAnimatedPatter.addEventListener("animationend", () => {
          item.classList.remove("card_patter-start-animation");
          animationActive = false;
        });
      } else {
        lastAnimatedPatter.addEventListener("animationend", () => {
          item.classList.add("card_patter-start-animation");
          animationActive = true;
          lastAnimatedPatter.addEventListener("animationend", () => {
            item.classList.remove("card_patter-start-animation");
            animationActive = false;
          });
        });
      }
    });
    item.addEventListener("mouseleave", () => {
      if (!lastAnimatedPatter) return
      if (!animationActive) {
        item.classList.add("card_patter-end-animation");
        animationActive = true;
        lastAnimatedPatter.addEventListener("animationend", () => {
          item.classList.remove("card_patter-end-animation");
          animationActive = false;
        });
      } else {
        lastAnimatedPatter.addEventListener("animationend", () => {
          item.classList.add("card_patter-end-animation");
          animationActive = true;
          lastAnimatedPatter.addEventListener("animationend", () => {
            item.classList.remove("card_patter-end-animation");
            animationActive = false;
          });
        });
      }
    });
  });
}

// Анимация карточек направлений при ховере
if (document.documentElement.clientWidth > 767) {
  let productsCard = document.querySelectorAll(".products__card");

  if (productsCard.length) {
    productsCard.forEach((card) => {
      card.addEventListener("mouseenter", () => {
        card.classList.remove("products__card_end-animation");
        card.classList.add("products__card_start-animation");
      });
      card.addEventListener("mouseleave", () => {
        card.classList.remove("products__card_start-animation");
        card.classList.add("products__card_end-animation");
      });
    });
  }
}
