// Header mobile
let burger = document.querySelector(".burger-container");
let header = document.querySelector(".header");
let html = document.querySelector("html");

if (burger) {
  burger.addEventListener("click", () => {
    header.classList.toggle("header_menu-opened");
    html.classList.toggle("overflow-hidden");

    let expanded = burger.getAttribute("aria-expanded") === 'true' || false;
    burger.setAttribute('aria-expanded', !expanded);
  });
}

let headerTabBg = document.querySelector(".header__tab-bg");
let headerTab = document.querySelectorAll(".header__tab");
let headerMenuItem = document.querySelectorAll(".header__menu-item");
let headerTabWrapper = document.querySelectorAll(".header__tab-wrapper");

// Header tabs
if (document.documentElement.clientWidth > 1150) {
  headerMenuItem.forEach((item) => {
    let itemHeaderTabWrapper = item.querySelector(".header__tab-wrapper");
    let itemHeaderTab = item.querySelector(".header__tab");
    if (itemHeaderTabWrapper) {
      function openMenu() {
        headerTabBg.classList.add("header__tab-bg_active");
        headerTab.forEach((tab) => {
          tab.classList.remove("header__tab_active");
        })
        itemHeaderTab.classList.add("header__tab_active");
        headerTabWrapper.forEach((wrapper) => {
          wrapper.classList.remove("header__tab-wrapper_active");
        })
        itemHeaderTabWrapper.classList.add("header__tab-wrapper_active");
        headerTabBg.style.height = `${itemHeaderTabWrapper.clientHeight}px`
      }
      item.addEventListener("mouseover", openMenu);
      item.addEventListener("focusin", openMenu);
    } else {
      item.addEventListener("mouseover", () => {
        headerTab.forEach((tab) => {
          tab.classList.remove("header__tab_active");
        })
        headerTabWrapper.forEach((wrapper) => {
          wrapper.classList.remove("header__tab-wrapper_active");
        })
        headerTabBg.classList.remove("header__tab-bg_active");
        headerTabBg.classList.remove("header__tab-bg_hovered");
      })
    }
  });

  function closeMenu() {
    headerTab.forEach((tab) => {
      tab.classList.remove("header__tab_active");
    })
    headerTabWrapper.forEach((wrapper) => {
      wrapper.classList.remove("header__tab-wrapper_active");
    })
    headerTabBg.classList.remove("header__tab-bg_active");
    headerTabBg.classList.remove("header__tab-bg_hovered");
  }

  header.addEventListener('mouseleave', closeMenu)
  header.addEventListener('focusout', closeMenu)

  document.addEventListener("click", (e) => {
    if (e.target.classList.contains("header__tab-wrapper") || e.target.classList.contains("header__tab-list")) {
      headerTabBg.classList.remove("header__tab-bg_active");
      headerTabBg.classList.remove("header__tab-bg_hovered");
      headerTab.forEach((tab) => {
        tab.classList.remove("header__tab_active");
      });
      headerTabWrapper.forEach((wrapper) => {
        wrapper.classList.remove("header__tab-wrapper_active");
      });
    }
  });

  headerTabWrapper.forEach((tabWrapper) => {
    tabWrapper.addEventListener("mouseover", () => {
      headerTabBg.classList.add("header__tab-bg_hovered");
    });
  });
} else {
  let headerMenuLink = document.querySelectorAll(".header__menu-link");
  let headerTab = document.querySelectorAll(".header__tab");
  let headerWrapper = document.querySelectorAll(".header__tab-wrapper");

  headerMenuLink.forEach((link, i) => {
    link.addEventListener("click", (e) => {
      link.classList.toggle("header__menu-link_active");
      headerMenuLink.forEach((item, j) => {
        if (j != i) {
          item.classList.remove("header__menu-link_active");
        }
      });
      let tabWrapper = link.closest('.header__menu-item').querySelector('.header__tab-wrapper');
      if (tabWrapper) {
        e.preventDefault();
        headerWrapper.forEach((wrapper, m) => {
          if (wrapper == tabWrapper) {
            wrapper.classList.toggle("header__tab-wrapper_active");
            wrapper.querySelector('.header__tab').classList.add("header__tab_active")
          } else {
            wrapper.classList.remove("header__tab-wrapper_active");
            wrapper.querySelector('.header__tab').classList.remove("header__tab_active")
          }
        });
      } else {
        headerWrapper.forEach((wrappers) => {
          wrappers.classList.remove("header__tab-wrapper_active");
        });
        headerTab.forEach((tabs) => {
          tabs.classList.remove("header__tab_active");
        });
      }
    });
  });

  let headerMenuItem = document.querySelectorAll('.header__menu-item');

  if (headerMenuItem.length && document.documentElement.clientWidth < 1151) {
    headerMenuItem.forEach((item) => {
      if (item.querySelector('.header__tab-wrapper')) {
        item.querySelector('.header__menu-link').classList.add('header__menu-link_with-plus')
      }
    })
  }

  document.addEventListener("click", (e) => {
    if (!e.target.closest(".header")) {
      headerWrapper.forEach((wrappers) => {
        wrappers.classList.remove("header__tab-wrapper_active");
      });
      headerTab.forEach((tabs) => {
        tabs.classList.remove("header__tab_active");
      });
    }
  });
}



document.addEventListener("scroll", () => {
  if (window.scrollY > 70) {
    header.classList.add("header_scroll");
  } else {
    header.classList.remove("header_scroll");
  }
});

// Кастомный курсор
let headerTabColumn = document.querySelectorAll('.header__tab-column');
let headerTabColumnIsHovered = false;
const customCursor = document.querySelector(".custom-cursor");

headerTabColumn.forEach((column) => {
  column.addEventListener('mouseenter', () => {
    headerTabColumnIsHovered = true
  })
  column.addEventListener('mouseleave', () => {
    headerTabColumnIsHovered = false
  })
})

if (customCursor) {
  const mouseMove = function (e) {
    let x = e.clientX;
    let y = e.clientY;
    customCursor.style.left = x + "px";
    customCursor.style.top = y + "px";
    if (headerTabBg.classList.contains('header__tab-bg_hovered') && !headerTabColumnIsHovered) {
      customCursor.classList.add('custom-cursor_active')
    } else {
      customCursor.classList.remove('custom-cursor_active')
    }
  };

  document.addEventListener("click", (e) => {
    if (e.target.classList.contains("header__tab-wrapper") || e.target.classList.contains("header__tab-list")) {
      customCursor.classList.remove('custom-cursor_active')
    }
  });

  document.addEventListener("mousemove", mouseMove);
}
