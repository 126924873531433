import {
  openModal, closeModal, modalSubscribeSuccessMessage, initModal
} from "./modal";

const validationDjango = (form, errors, field, fieldForm) => {
  if (errors && errors[field] && errors[field].length > 0 && fieldForm) {
    fieldForm.classList.add("is-invalid-backend");
    fieldForm.querySelector(".custom-input__error-backend").textContent = errors[field][0];
  } else {
    fieldForm.classList.remove("is-invalid-backend");
  }
};

const submitForm = (form) => {
  return fetch(form.action, {
    method: form.method,
    body: new FormData(form)
  });
};

const resetForm = (form) => {
  setTimeout(() => {
    window.clearForm(form);
  }, 1000);
};

const baseSuccessCallback = (form) => {
  resetForm(form);
};

const baseErrorCallback = (_) => {};

const subscribeFormSuccessCallback = (form) => {
  closeModal(form.closest('.modal'));
  submitForm(form).then((response) => {
      return response.json();
  }).then((resp) => {
    if (resp.status === 'ok') {
      resetForm(form);
      openModal(modalSubscribeSuccessMessage);
    } else {
      openModal(form.closest('.modal'));
    }
    const fieldForm = form.querySelector(`[data-validate-type="email"]`);
    validationDjango(form, resp.errors, 'email', fieldForm);
  });
};

const eventRegistrationSuccessCallback = (form) => {
  closeModal(form.closest('.modal'));
  submitForm(form).then((response) => {
      return response.json();
  }).then((resp) => {
    if (resp.status === 'ok') {
      resetForm(form);
      const modal = document.querySelector('.modal--registration-success-message');
      initModal('registration-success-message', modal);
      openModal(modal);
    } else {
      openModal(form.closest('.modal'));
    }
    const { errors } = resp;
    const listFields = document.querySelectorAll('[data-validate-field]');
    for (let i = 0; i < listFields.length; i++) {
      const field = listFields[i];
      validationDjango(form, errors, field.dataset.validateField, field);
    }
  });
};

const customExampleSuccessCallback = (form) => {
  resetForm(form);
  // eslint-disable-next-line no-console
  window.console.log('Ваша форма успешна отправлена');
};

const customExampleErrorCallback = (_) => {
  // eslint-disable-next-line no-console
  window.console.error('Отправка формы невозможна, заполните все обязательные поля');
};

const callbacks = {
  base: {
    successCallback: baseSuccessCallback,
    errorCallback: baseErrorCallback,
  },
  subscribeForm: {
    successCallback: subscribeFormSuccessCallback,
    errorCallback: baseErrorCallback,
  },
  eventRegistration: {
    successCallback: eventRegistrationSuccessCallback,
    errorCallback: baseErrorCallback,
  },
  customExample: {
    successCallback: customExampleSuccessCallback,
    errorCallback: customExampleErrorCallback,
  },
};

export const initFormValidate = () => {
  const formWrappers = [...document.querySelectorAll('[data-validate]')];
  if (formWrappers.length) {
    formWrappers.forEach((wrapper) => {
      let callback = wrapper.dataset.callback;
      if (!callback) {
        callback = 'base';
      }

      const formValidate = new window.FormsValidate(wrapper, callbacks[callback]);

      return formValidate.init();
    });
  }
};

initFormValidate();
